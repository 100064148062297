import bpa from '../../../assets/files/ru/Perechen_bankovskih_platezhnyh_agentov_01_07_2024.pdf';

export default {
    seo: {
        title: 'List of BPA | Bank 131',
        description: 'List of BPA'
    },
    title: 'List of BPA',
    content: [
        {
            label: 'Posting date 01.07.2024',
            title: 'List of BPA dated July 01, 2024.pdf',
            link: bpa
        }
    ],
}