import bpa from '../../../assets/files/ru/Perechen_bankovskih_platezhnyh_agentov_01_07_2024.pdf';

export default {
    seo: {
        title: 'Перечень БПА | Банк 131',
        description: 'Перечень БПА'
    },
    title: 'Перечень БПА',
    content: [
        {
            label: 'Дата размещения 01.07.2024',
            title: 'Перечень банковских платежных агентов на дату 01.07.2024 г.pdf',
            link: bpa
        }
    ],
}