import React from 'react';

import TemplateLegalPage from '../../blocks/templateLegalPage';

import Rectangle from "../../components/Rectangle";
import RectangleLink from "../../components/RectangleLink";
import Clarification from "../../components/Clarification";

import enMessages from '../../i18n/legal/bpa/en.js'
import ruMessages from '../../i18n/legal/bpa/ru.js'

import enMainMessages from '../../i18n/LegalInformation/en.js'
import ruMainMessages from '../../i18n/LegalInformation/ru.js'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const messagesMain = {
    en: enMainMessages,
    ru: ruMainMessages
}

const BpaPage = ({ pathContext: { locale } }) => {
    const section = 'bpa';

    return (
        <TemplateLegalPage
            locale={locale}
            section={section}
            messages={messages[locale]}
            messagesMain={messagesMain[locale]}
        >
            {messages[locale].content.map((item, index) => (
                <RectangleLink
                    key={`rectangle_${index}`}
                    title={item.title}
                    label={item.label}
                    link={item.link}
                    target="_blank"
                />
            ))}
        </TemplateLegalPage>
    )
};

export default BpaPage;
